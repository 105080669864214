.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

body {
  font: 14px "Century Gothic", Futura, sans-serif;
  margin: 20px;
}

ol, ul {
  padding-left: 30px;
}

.board-row:after {
  clear: both;
  content: "";
  display: table;
}

.board-col {
  display: flex;
  flex-direction: column;
}

.status {
  margin-bottom: 10px;
}

.gamestatus {
  margin-bottom: 10px;
  font-size: 18px;
}

.smallstatus {
  margin-top: -20px;
  font-size: 15px;
  font-weight: normal;
}

.square {
  background: #fff;
  border: 1px solid #999;
  float: left;
  font-size: 24px;
  font-weight: bold;
  line-height: 34px;
  height: 34px;
  margin-right: -1px;
  margin-top: -1px;
  padding: 0;
  text-align: center;
  width: 34px;
}

.card {
  border: 1px solid #999;
  float: left;
  font-size: 22px;
  font-weight: bold;
  line-height: 100px;
  height: 100px;
  margin-right: 3px;
  margin-top: -1px;
  padding: 0;
  text-align: center;
  width: 68px;
}

.nextturnbutton {
  border: 2px solid #000;
  background-color: #D55;
  float: left;
  font-size: 15px;
  font-weight: bold;
  line-height: 35px;
  height: 35px;
  margin-right: 3px;
  margin-top: 10px;
  padding: 0;
  text-align: center;
  width: 180px;
  border-radius: 5px;
}

.cardmorespace {
  border: 1px solid #999;
  float: left;
  font-size: 24px;
  font-weight: bold;
  line-height: 100px;
  height: 100px;
  margin-right: 33px;
  margin-left: 34px;
  margin-top: -1px;
  padding: 0;
  text-align: center;
  width: 68px;
}

.trump {
  width: 100px;
  margin-left: -20px;
}

.cardheart {
  background-image: url("heart.png");
  background-size: 30px;
  background-repeat: no-repeat;
  background-position-x: right;
}

.cardclub {
  background-image: url("club.png");
  background-size: 30px;
  background-repeat: no-repeat;
  background-position-x: right;
}

.cardspade {
  background-image: url("spade.png");
  background-size: 30px;
  background-repeat: no-repeat;
  background-position-x: right;
}

.carddiamond {
  background-image: url("diamond.png");
  background-size: 30px;
  background-repeat: no-repeat;
  background-position-x: right;
}

.player {
  background: #fff;
  border: 1px solid #999;
  float: left;
  font-size: 20px;
  font-weight: bold;
  line-height: 68px;
  height: 68px;
  margin-right: 3px;
  margin-top: -1px;
  padding: 0;
  text-align: center;
  width: 135px;
}

.trumpbutton {
  background: #FFF;
  border: 1px solid #FFF;
  margin-left: 0px;
  height: 130px;
  width: 150px;
}

.startpage {
  background: #BBB;
  border: 1px solid #333;
  margin-left: 0px;
  height: 20px;
  width: 100px;
}

.usrlabel {
  padding-right: 5px;
  border-right: 10px;
}

.playertoplay {
  background: #fff;
  border: 1px solid #999;
  background-color: #D55;
  float: left;
  font-size: 20px;
  font-weight: bold;
  line-height: 68px;
  height: 68px;
  margin-right: 3px;
  margin-top: -1px;
  padding: 0;
  text-align: center;
  width: 135px;
}

.playerscore {
  background: #fff;
  float: left;
  font-size: 20px;
  font-weight: bold;
  line-height: 30px;
  height: 30px;
  margin-right: 3px;
  margin-top: -1px;
  padding: 0;
  text-align: center;
  width: 500px;
}

.playerscorehighlight {
  background: #D55;
  float: left;
  font-size: 20px;
  font-weight: bold;
  line-height: 30px;
  height: 30px;
  margin-right: 3px;
  margin-top: -1px;
  padding: 0;
  text-align: center;
  width: 500px;
}

.square:focus {
  outline: none;
}

.kbd-navigation .square:focus {
  background: #ddd;
}

.game {
  display: flex;
  flex-direction: column;
}

.game-board {
  display: flex;
  flex-direction: row;
}

.game-board-col {
  display: flex;
  flex-direction: column;
}
.game-info {
  margin-left: 20px;
}
